import ls from "../utils/ls"

const { parseUrl, fetchAPI, memoizedFetch } = require("../utils/utils")

async function setupUserPage() {
    const id = getUserId()

    const url = `${API_URL}/users/${id}`

    const user = await fetchAPI(url)
    makeUser(user)

    const roles = await getRoles()
    makeRoles(roles, user.role_id)

}

function makeUser({ id, avatar, username, full_name, email, phone, phone_verified_at, cnic, birth_date, role_id }) {
    const avatarFile = avatar ? avatar : 'user.jpg'
    const idElements = document.querySelectorAll('input[name="id"]')
    idElements.forEach((element) => element.value = id)

    const phoneInput = document.querySelector('.input-field[data-name="phone"] input')

    document.querySelector('.profile-image').style.backgroundImage = `url(${STORAGE_URL}/avatars/${avatarFile})`
    document.querySelector('.input-field[data-name="username"] input').value = username
    document.querySelector('.input-field[data-name="full_name"] input').value = full_name
    document.querySelector('.input-field[data-name="email"] input').value = email
    phoneInput.value = phone
    document.querySelector('.input-field[data-name="CNIC"] input').value = cnic
    document.querySelector('.input-field[data-name="birth_date"] input').value = birth_date

    if (phone_verified_at) {
        phoneInput.disabled = true
    }
}

async function getRoles() {
    const url = `${API_URL}/roles`
    const roles = await memoizedFetch('roles', url)
    return roles
}

function makeRoles(roles, selectedValue) {
    const authUser = ls.get('authUser')
    const authUserRole = getUserRole(authUser)
    const superAdminRole = roles.find((role) => role.short_name === 'super_admin')
    const selectElement = document.querySelector('.input-field[data-name="role"] select')
    for (const { id, full_name, auth_level } of roles) {
        const optionElement = document.createElement('option')
        optionElement.value = id
        optionElement.innerText = full_name

        if (authUserRole.auth_level <= auth_level || auth_level === superAdminRole.auth_level) {
            optionElement.disabled = true
        }

        selectElement.appendChild(optionElement)
    }
    $('.input-field[data-name="role"] select')
        .val(selectedValue)
        .selectmenu('refresh')
}

function getUserId() {
    const url = parseUrl(location.href)

    const id = url.searchParams.get('id')
    if (!id) return undefined

    return parseInt(id)
}

export async function getUserRole(user) {
    const roles = await getRoles()
    return roles.find((role) => user.role_id === role.id)
}

export default {
    setupUserPage,
}