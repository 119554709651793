import ls from "./ls"

export function fetchAPI(url, args = {}) {
    const token = ls.get('token')

    const { body, method, rethrowError = false } = args

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Credentials': 'include',
        },
        method: method || 'GET',
        body: body && JSON.stringify(body),
    })
        .then(async (res) => {
            const body = await res.json()
            if (!res.ok) {
                const errors = body.hasOwnProperty('errors') ? body.errors : null
                let error = ""
                if (typeof errors === "string") {
                    error = errors
                } else if (Array.isArray(errors)) {
                    error = errors[0]
                } else {
                    error = res.statusText
                }
                throw new Error(error)
            }

            return body
        })
        .catch((error) => {
            if (rethrowError) {
                throw new Error(error)
            }
            console.log(error);
        })
}

export async function memoizedFetch(key, url, args = {}) {
    if (!url) {
        throw Error('url must be supplied to function')
    }
    const memo = ls.get(key)
    if (memo) {
        return memo
    }

    let result = await fetchAPI(url, args)
    if (result.data) {
        ls.set(key, result.data)
        return result.data
    }
    return
}

export function debounce(func, wait) {
    let timeout;

    return function (...args) {
        let context = this
        let later = function () {
            timeout = null
            func.apply(context, args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }
}

export function parseUrl(urlToParse) {
    const url = new URL(urlToParse)
    const searchString = url.search.slice(1)
    const keyVals = searchString.split('&')
    keyVals.map((keyVal) => keyVal.split('='))
        .forEach(([key, val]) => {
            if (key.length > 0 && typeof val !== 'undefined') {
                url.searchParams.append(key, val)
            }
        })
    return url
}

function displayError(error) {
    const errorText = error ?? 'There has been an error'
    $('.error-bar').text(errorText);
    $('body').animate({
        scrollTop: 0
    }, function () {
        $('.error-bar').slideDown().delay(5000).slideUp();
    });
}

function displaySuccess(success) {
    const successText = success ?? 'Success'
    $('.success-bar').text(successText);
    $('body').animate({
        scrollTop: 0
    }, function () {
        $('.success-bar').slideDown().delay(5000).slideUp();
    });
}

export default {
    fetchAPI,
    memoizedFetch,
    debounce,
    parseUrl,
    displayError,
    displaySuccess,
}