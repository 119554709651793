import Echo from "laravel-echo"

const server = process.env.LOCAL_SERVER
const port = process.env.WEBSOCKETS_PORT || 6001
const host = process.env.WEBSOCKETS_HOST || window.location.hostname
const key = process.env.PUSHER_KEY
const cluster = process.env.PUSHER_CLUSTER || null

function start(token) {
    let echoConfig = {
        broadcaster: 'pusher',
        key: key,
        cluster: cluster,
        namespace: 'App.API.Events',
        auth: {
            headers: {
                Authorization: `Bearer ${token}`
            }
        },
        authEndpoint: `${server}/api/broadcasting/auth`,
    }

    if (process.env.NODE_ENV === 'development') {
        echoConfig['wsHost'] = host
        echoConfig['wsPort'] = port
        echoConfig['forceTLS'] = false
        echoConfig['disableStats'] = true
    }

    window.Pusher = require('pusher-js')

    window.Echo = new Echo(echoConfig)
}

export function joinChannel(channelName, eventHandlers) {
    const channel = window.Echo.private(channelName)

    eventHandlers.forEach(({ name, handle }) => {
        channel.listen(name, handle)
    })
}

export function leaveChannel(channelName, eventHandlers) {
    const channel = window.Echo.private(channelName)

    eventHandlers.forEach(({ name }) => {
        channel.stopListening(name)
    })
}

function stop() {
    window.Pusher = undefined;
    window.Echo = undefined;
}

export default {
    start,
    stop,
}