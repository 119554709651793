import { initialiseMessages, addNewMessage, markMessageAsDeleted } from '../messages/messages'
import { fetchAPI, memoizedFetch } from '../utils/utils'
import * as maps from '../utils/maps'
import { joinChannel } from '../utils/echo'

async function setupCasePage() {
    const id = getCaseId()
    const pageSetup = [
        makeUrgencies(),
    ]

    try {
        await Promise.all(pageSetup)
        let categories = await getCategories()
        let recipients = await getRecipients()
        let caseItem = await getCase(id)
        maps.initMap()
        makeIssues(caseItem.issue_id)
        makeCase(caseItem, recipients, categories)
        initialiseMessages(caseItem)
        joinCaseChat(caseItem.chat_id)
        joinCaseChannel(caseItem.id)
    } catch (error) {
        console.error(error);
    }
}

function joinCaseChat(chatId) {

    const handleMessageSent = ({ message }) => {
        addNewMessage(message)
    }

    const handleMessageDeleted = ({ message }) => {
        markMessageAsDeleted(message)
    }

    const eventHandles = [
        {
            'name': 'MessageSentEvent',
            'handle': handleMessageSent,
        },
        {
            'name': 'MessageDeletedEvent',
            'handle': handleMessageDeleted
        }
    ]

    joinChannel(`chat.${chatId}`, eventHandles)
}

function joinCaseChannel(caseId) {

    const handleLocationUpdate = ({ location }) => {
        updateLocation(location.latitude, location.longitude, location.status.short_name)
    }
    const handleCaseDeleted = async ({ caseItem }) => {
        const recipients = await getRecipients()
        let categories = await getCategories()
        makeCase(caseItem, recipients, categories)
    }
    const handleCaseUpdated = async ({ caseItem }) => {
        const recipients = await getRecipients()
        let categories = await getCategories()
        makeCase(caseItem, recipients, categories)
    }
    const eventHandles = [
        {
            'name': 'GeolocationUpdatedEvent',
            'handle': handleLocationUpdate
        },
        {
            'name': 'CaseDeletedEvent',
            'handle': handleCaseDeleted
        },
        {
            'name': 'CaseUpdatedEvent',
            'handle': handleCaseUpdated
        },
    ]

    joinChannel(`case.${caseId}`, eventHandles)
}

function getCase(id) {
    const url = `${API_URL}/cases/${id}`
    return fetchAPI(url)
        .then(({ data }) => data)
}

function makeCase(caseItem, recipients, categories) {
    const { user, recipients_id, urgency_id, category_id, issue_id, latitude, longitude, status, responder } = caseItem
    const initiatorElement = document.querySelector('.initiator')
    initiatorElement.innerText = `Requester: ${user.full_name}`
    initiatorElement.dataset.id = user.id

    const responderElement = document.querySelector('.helper')
    responderElement.innerText = (responder !== null) ? `Responder: ${responder.full_name}` : 'No assigned Responder'
    responderElement.dataset.id = (responder !== null) ? responder.id : ''

    const recipient = recipients[recipients_id]
    document.querySelector('.request-to').innerText = `Help from ${recipient.name}`
    $('.case-urgency-dropdown').val(urgency_id).selectmenu('refresh')
    $('.case-category-dropdown').val(category_id).selectmenu('refresh')

    $('.case-urgency-dropdown').on("selectmenuchange", handleUrgencyChange)
    $('.case-parent-issue-dropdown').on('selectmenuchange', handleParentIssueChange)
    $('.case-child-issue-dropdown').on('selectmenuchange', handleChildIssueChange)


    document.querySelector('.status-heading').classList.add(`status-${status.short_name}`)
    const category = categories[category_id]
    document.querySelector('.category').innerText = `Need help with ${category.name} ${category.emoji}`

    const completeButton = document.querySelector('.complete-button')
    completeButton.addEventListener('click', handleCompleteCase)

    const cannotCompleteCase = status.short_name !== 'in_progress' || recipient.name.toLowerCase() === 'friends'
    if (cannotCompleteCase) completeButton.disabled = true

    updateLocation(latitude, longitude, status.short_name)
}

function handleCompleteCase(e) {
    const id = getCaseId()
    const url = `${API_URL}/cases/${id}/complete`
    fetchAPI(url, {
        method: 'POST'
    })
}

async function updateCase(caseId, body) {
    const url = `${API_URL}/cases/${caseId}`
    let result = await fetchAPI(url, {
        method: 'PATCH',
        body,
    })
    const caseItem = result.data
    const recipients = await getRecipients()
    const categories = await getCategories()

    makeCase(caseItem, recipients, categories)
}

function updateLocation(latitude, longitude, status) {
    maps.setCentre({
        thisMap: map,
        lat: latitude,
        lng: longitude,
        zoom: 15,
    })
    const marker = maps.createMarker(map, {
        lat: latitude,
        lng: longitude,
        pinType: status,
    })
    maps.clearMarkers(caseMarkers)
    caseMarkers.push(marker)
}

async function getCategories() {
    const url = `${API_URL}/categories`
    return memoizedFetch('categories', url)
}

function makeUrgencies() {

    const urgenciesSelectElement = document.querySelector('.case-urgency-dropdown')
    const url = `${API_URL}/urgencies`

    return fetchAPI(url)
        .then(({ data }) => {
            data.forEach(({ id, description }) => {
                const option = document.createElement('option')
                option.appendChild(document.createTextNode(description))
                option.value = id
                urgenciesSelectElement.appendChild(option)
            })
        })
}

async function makeIssues(issue_id) {
    const issuesParentElement = document.querySelector('.case-parent-issue-dropdown')
    const issues = await getIssues()
    fillSelect(issuesParentElement, issues, 'Select a parent issue')

    if (issue_id) {
        const parentIssue = await getParentIssue(issue_id)
        $('.case-parent-issue-dropdown').val(parentIssue.id).selectmenu('refresh')

        const issuesChildElement = document.querySelector('.case-child-issue-dropdown')
        fillSelect(issuesChildElement, parentIssue.children, 'Select a child issue')
        $('.case-child-issue-dropdown').val(issue_id).selectmenu('refresh')
    }
}

async function getParentIssue(issue_id) {
    const issues = await getIssues()
    for (let i = 0; i < issues.length; i++) {
        const issue = issues[i];
        const issueFound = issue.children.some(({ id }) => id === issue_id)
        if (issueFound) {
            return issue
        }
    }
    return
}

export async function getParentAndChildIssues(issue_id) {
    const issues = await getIssues()
    for (let i = 0; i < issues.length; i++) {
        const parentIssue = issues[i];
        const childIssue = parentIssue.children.find(({ id }) => id === issue_id)
        if (childIssue) {
            return [parentIssue, childIssue]
        }
    }
    return []
}

function fillSelect(container, items, emptyOption = null) {
    clearSelect(container)
    if (emptyOption !== null) {
        const emptyOptionElement = createOption(emptyOption, '', { disabled: true, selected: true })
        container.appendChild(emptyOptionElement)
    }
    items.forEach(({ id, name }) => {
        const optionElement = createOption(name, id)
        container.appendChild(optionElement)
    })
    $(container).selectmenu('refresh')
}

function createOption(name, value, attributes = {}) {
    const optionElement = document.createElement('option')
    optionElement.value = value
    optionElement.innerText = name
    if (Object.keys(attributes).length > 0) {
        Object.entries(attributes).forEach(([name, value]) => {
            optionElement[name] = value
        })
    }
    return optionElement
}

function clearSelect(container) {
    container.innerText = null
    $(container).selectmenu('refresh')
}

export async function getIssues() {
    const url = `${API_URL}/issues`

    return await memoizedFetch('issues', url)
}

async function getRecipients() {
    const url = `${API_URL}/recipients`

    return await memoizedFetch('recipients', url)
}

function updateUrgency(caseId, urgencyId) {
    const data = {
        'urgency_id': urgencyId,
    }
    updateCase(caseId, data)
}

async function updateParentIssue(parentIssueId) {
    const issuesChildElement = document.querySelector('.case-child-issue-dropdown')
    const issues = await getIssues()

    const parentIssue = issues.find(({ id }) => parseInt(parentIssueId) === id)

    fillSelect(issuesChildElement, parentIssue.children, 'Select a child issue')
}

function updateChildIssue(caseId, childIssueId) {
    const data = {
        issue_id: parseInt(childIssueId),
    }
    updateCase(caseId, data)
}

function handleUrgencyChange(e) {
    const caseId = getCaseId()
    sos.case.updateUrgency(caseId, e.target.value)
}

function handleParentIssueChange(e) {
    sos.case.updateParentIssue(e.target.value)
}

function handleChildIssueChange(e) {
    const caseId = getCaseId()
    sos.case.updateChildIssue(caseId, e.target.value)
}

export function getCaseId() {
    const url = new URL(location.href)
    const pathNames = url.pathname.split('/')
    const id = pathNames[pathNames.length - 1]
    return id
}

export default {
    setupCasePage,
    updateUrgency,
    updateParentIssue,
    updateChildIssue,
}