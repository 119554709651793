import ls from '../utils/ls'
import maps from '../utils/maps'
import { fetchAPI } from '../utils/utils'

function makeUserCard(user, template) {
    const clone = template.content.cloneNode(true)
    clone.querySelector('.card-title').textContent = user.full_name
    clone.querySelector('.username').textContent = `@${user.username}`
    clone.querySelector('.role').textContent = user.role?.full_name
    clone.querySelector('.create-date').textContent = user.created_at ? moment(user.created_at).format('LL') : 'As a seed'
    clone.querySelector('.image').style.backgroundImage = user.avatar ? `url(${STORAGE_URL}/avatars/${user.avatar})` : `url(${STORAGE_URL}/avatars/user.jpg)`

    if (isDisabledUsersView) {
        clone.querySelector('.enable-user').href = `/users/enable?id=${user.id}`
    } else {
        clone.querySelector('.edit-user').href = `/users/edit?id=${user.id}`
        clone.querySelector('.delete-user').href = `/users/delete?id=${user.id}`
    }

    return clone
}

function clearUsers() {
    const usersContainer = document.querySelector('.user-cards')
    usersContainer.innerHTML = null
}

function fetchUsers(baseUrl, clear) {

    const searchInputElement = document.querySelector('.searchBar input')

    const location = new URL(document.location.href)
    const searchParams = location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
    const viewDisabled = searchParams.some(p => p[0] === 'disabled')

    const searchTerm = searchInputElement.value ?? null

    const url = new URL(baseUrl)

    viewDisabled && url.searchParams.append('active', 0)
    searchTerm && url.searchParams.append('s', searchTerm)

    return fetchAPI(url)
        .then(({ data, next_page_url }) => {
            const usersContainer = document.querySelector('.user-cards')
            const template = document.getElementById('user-card-template')
            const authUser = ls.get('authUser')

            if (clear) {
                clearUsers()
            }
            data.forEach((user) => {
                if (user.id === authUser.id) return

                const clone = makeUserCard(user, template)
                usersContainer.appendChild(clone)
            })

            const controls = document.querySelector('.controls')
            if (next_page_url) {
                const readMoreButton = controls.querySelector('.read-more')
                readMoreButton.dataset.url = next_page_url

                controls.style.display = 'block';
                readMoreButton.removeEventListener('click', handleLoadMore)
                readMoreButton.addEventListener('click', handleLoadMore)
            } else {
                controls.style.display = 'none'
            }
        })
}

export async function markUsersOnMap() {
    const thisMap = sosMaps['users-map']

    const { latitude, longitude } = ls.get('authUser')

    const url = new URL(`${API_URL}/users`)
    url.searchParams.append('distance', 20000)
    latitude && url.searchParams.append('latitude', latitude)
    longitude && url.searchParams.append('longitude', longitude)
    url.searchParams.append('role', 'helper')
    url.searchParams.append('getLocation', true)

    try {
        const { data, total } = await fetchAPI(url)
        const responders = data

        userMarkers = responders.map(({ latitude, longitude, id, full_name }) => {
            if (latitude) {
                const onClick = (e) => {
                    location.href = `${WEB_URL}/users/edit?id=${id}`
                }
                return maps.createMarker(thisMap, {
                    lat: latitude,
                    lng: longitude,
                    pinType: 'user',
                    title: full_name,
                    onClick: onClick,
                })
            } else {
                return null
            }
        })

        markAdminOnMap(thisMap)
        const markersToFit = adminMarker ? [...userMarkers, adminMarker] : userMarkers
        maps.fitMarkers(thisMap, markersToFit)
    } catch (error) {
        console.error(error);
    }
}

export function markAdminOnMap(thisMap) {
    const authUser = ls.get('authUser')

    if (authUser && authUser.latitude && authUser.longitude) {
        const { latitude, longitude } = authUser
        adminMarker = maps.createMarker(thisMap, { lat: latitude, lng: longitude })
    }
    return adminMarker
}

function handleLoadMore(e) {

    const buttonElement = e.target
    const url = buttonElement.dataset.url

    buttonElement.textContent = 'Loading ...'
    buttonElement.disabled = true
    fetchUsers(url)
        .catch((errors) => {
            console.log(errors)
        })
        .finally(() => {
            buttonElement.textContent = 'Load more'
            buttonElement.disabled = false
        })
}

function handleSearch(e) {
    const url = `${API_URL}/users?s=${e.target.value}`
    const clear = true
    fetchUsers(url, clear)
        .catch((error) => console.log(error))
}

function handleUpdateLocation(e) {
    const thisMap = sosMaps['requests-map']

    const success = ({ coords }) => {
        const { latitude, longitude } = coords
        const authUser = ls.get('authUser')
        updateUserLocation(authUser.id, latitude, longitude)
            .then(({ data }) => {
                ls.set('authUser', data)
                adminMarker && maps.clearMarker(adminMarker)
                adminMarker = maps.createMarker(thisMap, { lat: latitude, lng: longitude })
            })
    }
    const error = (error) => {
        console.log('Unable to get location', error);
    }
    navigator.geolocation.getCurrentPosition(success, error)
}

function updateUserLocation(userId, latitude, longitude) {
    const url = `${API_URL}/users/${userId}`
    const body = {
        latitude,
        longitude,
    }
    return fetchAPI(url, {
        method: 'PATCH',
        body,
    })
        .catch((error) => console.error(error))

}

export default {
    makeUserCard,
    clearUsers,
    fetchUsers,
    handleSearch,
    handleUpdateLocation,
    markUsersOnMap,
}