import echo from '../utils/echo'
import ls from '../utils/ls'
import { memoizedFetch } from '../utils/utils'

async function initialiseApp() {
    const token = ls.get('token')

    echo.start(token)

    makeVersion()

    $('#logout-button').on('click', (e) => {
        ls.clearStorage()
    })

    $('.sidebar .user').on('click', function () {
        var controls = $(this).find('.user-controls');
        controls.toggleClass('shown');
    });

    if (token) {
        await makeUserProfile()
    }
}

async function makeUserProfile() {
    const authUser = ls.get('authUser')
    const roles = await memoizedFetch('roles', `${API_URL}/roles`)

    const userProfileCard = document !== null && document.querySelector('.sidebar .user')

    if (!userProfileCard) return

    userProfileCard.querySelector('.name').innerText = authUser.full_name
    userProfileCard.querySelector('.username').innerText = `@${authUser.username}`
    userProfileCard.querySelector('.role').innerText = roles[authUser.role_id - 1].full_name

    userProfileCard.querySelector('.user-image').style = `
    background-image:url("${authUser.avatar ? `${STORAGE_URL}/avatars/${authUser.avatar}` : `${STORAGE_URL}/avatars/user.jpg`}")
    `
}

function makeVersion() {
    const version = process.env.VERSION
    const versionContainer = document.querySelector('.version')
    if (versionContainer) {
        versionContainer.innerText = `v${version}`
    }
}

export default {
    initialiseApp,
}