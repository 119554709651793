import { fetchAPI, memoizedFetch, parseUrl } from '../utils/utils'
import * as maps from '../utils/maps'
import { getParentAndChildIssues } from './case'
import { markAdminOnMap } from '../users/users'
import ls from '../utils/ls'

async function markOpenCasesOnMap() {
    const thisMap = sosMaps['requests-map']

    try {
        let { data } = await getCases({ isOpen: 'true' })
        const cases = data.data

        dashboardMarkers = cases.map(({ latitude, longitude, status, user, id }) => {
            if (latitude) {
                const handleClick = () => {
                    location.href = `${WEB_URL}/cases/${id}`
                }
                return maps.createMarker(thisMap, {
                    lat: latitude,
                    lng: longitude,
                    pinType: status?.short_name,
                    title: `${id}: ${user.full_name}`,
                    onClick: handleClick
                })
            } else {
                return null
            }
        })

        markAdminOnMap(thisMap)
        const markersToFit = adminMarker ? [...dashboardMarkers, adminMarker] : dashboardMarkers
        maps.fitMarkers(thisMap, markersToFit)
    } catch (error) {
        console.error(error);
    }
}

function getOpenCases() {
    const openCasesListElement = document.querySelector('.open-cases ul')
    const template = document.getElementById('case-item-template')
    const isOpen = 'true'
    getCases({ isOpen, limit: 5 })
        .then(({ data: { data } }) => {
            clearItems(openCasesListElement)
            data.forEach((caseItem) => {
                const clone = makeCaseListItem(caseItem, template)
                openCasesListElement.appendChild(clone)
            })
        })
        .catch((error) => {
            console.error(error);
        })
}

function getClosedCases() {
    const closedCasesListElement = document.querySelector('.closed-cases ul')
    const template = document.getElementById('case-item-template')

    getCases({ isOpen: 'false', limit: 5 })
        .then(({ data: { data } }) => {
            clearItems(closedCasesListElement)
            data.forEach((caseItem) => {
                const clone = makeCaseListItem(caseItem, template)
                closedCasesListElement.appendChild(clone)
            })
        })
        .catch((error) => {
            console.error(error);
        })
}

function getAllCases() {
    const casesContainerElement = document.querySelector('.cases-container')
    clearItems(casesContainerElement)
    getAllCasesBase()
}

function getAllCasesBase(args) {
    const { url = null } = args || {}
    const casesWrapperElement = document.querySelector('.cases-wrapper')
    const casesContainerElement = document.querySelector('.cases-container')
    const template = document.getElementById('case-item-template')
    const controlsTemplate = document.getElementById('controls-template')

    return getCases({ isOpen: 'true', url })
        .then(async ({ data: { data, next_page_url } }) => {
            for (const caseItem of data) {
                const clone = await makeCaseItem(caseItem, template)
                casesContainerElement.appendChild(clone)
            }
            let loadMoreElement = casesWrapperElement.querySelector('.read-more')
            if (loadMoreElement) {
                loadMoreElement.remove()
            }
            if (next_page_url) {
                loadMoreElement = makeLoadMoreControls(next_page_url, controlsTemplate)
                casesWrapperElement.appendChild(loadMoreElement)
            }
        })
        .catch((error) => {
            console.error(error);
        })
}

function getCases({ url, isOpen, limit }) {

    const casesUrl = url ? new URL(url) : new URL(API_URL + '/cases')

    const currentUrl = parseUrl(location.href)
    const status = currentUrl.searchParams.get('status')

    status && casesUrl.searchParams.append('status', status)
    isOpen && casesUrl.searchParams.append('open', isOpen)
    limit && casesUrl.searchParams.append('limit', limit)

    return fetchAPI(casesUrl)
}


function handleLoadMore(e) {
    const buttonElement = e.target
    const url = buttonElement.dataset.url

    buttonElement.textContent = 'Loading ...'
    buttonElement.disabled = true
    getAllCasesBase({ url })
        .catch((errors) => {
            console.log(errors)
        })
        .finally(() => {
            buttonElement.textContent = 'Load more'
            buttonElement.disabled = false
        })
}

function makeLoadMoreControls(url, template) {
    const clone = template.content.cloneNode(true)
    const buttonElement = clone.querySelector('.read-more')
    buttonElement.dataset.url = url
    buttonElement.addEventListener('click', handleLoadMore)
    return clone
}

function makeCaseListItem(caseItem, template) {
    const clone = template.content.cloneNode(true)
    const listElement = clone.querySelector('li')
    const linkElement = clone.querySelector('a')

    linkElement.innerText = `${caseItem.id}: ${caseItem.user.full_name} ${moment(caseItem.created_at).format('LTS L')}`
    linkElement.href = `/cases/${caseItem.id}`

    !caseItem.issue_id && linkElement.classList.add('unclassified')

    listElement.classList.add(`status-${caseItem.status.short_name}`)

    return clone
}

async function makeCaseItem({ id, user, status, responder, admin, issue_id, created_at }, template) {
    const authUser = ls.get('authUser')
    const roles = await memoizedFetch('roles', `${API_URL}/roles`)
    const authUserRole = roles[authUser.role_id - 1]

    const clone = template.content.cloneNode(true)
    //    clone.querySelector('.card').classList.add(status.short_name)
    clone.querySelector('.card-header').classList.add(`status-${status.short_name}`)
    clone.querySelector('.card-title').innerText = `${id}: ${user.full_name} ${moment(created_at).format('LTS L')}`
    clone.querySelector('.initiator').innerText = user.full_name
    clone.querySelector('.modify-date').innerText = moment(created_at).format('LTS L')
    clone.querySelector('.helper').innerText = responder ? responder.full_name : 'No responder'

    const adminElement = clone.querySelector('.admin')

    if (authUserRole.short_name === 'super_admin') {
        adminElement.innerText = admin ? admin.full_name : 'No admin assigned'
    } else {
        adminElement.remove()
    }

    const [parentIssue, childIssue] = await getParentAndChildIssues(issue_id)
    clone.querySelector('.issue').innerHTML = issue_id ? `${parentIssue.name} <span class="separator">&gt;</span> ${childIssue.name}` : 'No issue assigned'

    //clone.querySelector('.delete-case').href = `/cases/delete?case=${id}`
    clone.querySelector('.case-link').href = `/cases/${id}`

    return clone
}

function clearItems(listElement) {
    listElement.textContent = null
}

export default {
    getOpenCases,
    getClosedCases,
    getAllCases,
    markOpenCasesOnMap,
}