/**
 * Initialise the map with center and zoom from data atrributes
 */
export function initMap() {
    $('.map').each(function () {
        var center = $(this).data('center');
        var zoom = $(this).data('zoom');
        map = new google.maps.Map($(this).get(0), {
            center: {
                lat: center[0],
                lng: center[1]
            },
            zoom: zoom,
            disableDefaultUI: true
        });
    });
}
export function initMaps(mapClassNames) {
    mapClassNames.forEach((mapName) => {
        const mapElement = document.querySelector(`.${mapName}`)
        var center = JSON.parse(mapElement.dataset.center)
        var zoom = parseInt(mapElement.dataset.zoom)
        const newMap = new google.maps.Map(mapElement, {
            center: {
                lat: center[0],
                lng: center[1]
            },
            zoom: zoom,
            disableDefaultUI: true
        });
        sosMaps[mapName] = newMap
    })
}
/**
 * Create a marker on the global map.
 * 
 * If status is set, use special marker
 */
export function createMarker(thisMap, { lat, lng, pinType, title, onClick }) {

    var image = pinType && {
        url: WEB_URL + '/assets/images/geo-marker-' + pinType + '.png',
        size: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 27)
    };
    var shape = pinType && {
        coords: [16, 27, 8, 19, 7, 11, 13, 4, 24, 11, 24, 19],
        type: 'poly'
    };
    const markerOptions = {
        position: {
            lat,
            lng
        },
        map: thisMap,
    }
    pinType ? markerOptions.icon = image : null
    pinType ? markerOptions.shape = shape : null
    title ? markerOptions.title = title : null

    var marker = new google.maps.Marker(markerOptions);

    if (onClick) {
        marker.addListener('click', () => onClick())
    }

    return marker
}

export function setCentre({ thisMap, lat, lng, zoom }) {
    const latLng = new google.maps.LatLng(lat, lng)
    thisMap.setCenter(latLng)
    thisMap.setZoom(zoom)
}

export function fitMarkers(thisMap, markers) {
    let minLatitude, maxLatitude, minLongitude, maxLongitude

    markers.forEach((marker) => {
        if (!marker) return
        const { position } = marker
        const latitude = position.lat()
        const longitude = position.lng()
        if (!minLatitude) {
            minLatitude = maxLatitude = latitude
            minLongitude = maxLongitude = longitude
        }
        if (latitude < minLatitude) minLatitude = latitude
        if (latitude > maxLatitude) maxLatitude = latitude
        if (longitude < minLongitude) minLongitude = longitude
        if (longitude > maxLongitude) maxLongitude = longitude
    })
    thisMap.fitBounds({
        west: minLongitude,
        east: maxLongitude,
        north: maxLatitude,
        south: minLatitude,
    })
}

export function clearMarkers(markers) {
    if (markers.length > 0) {
        markers.forEach((marker) => {
            marker.setMap(null)
        })
        markers = []
    }
}

export function clearMarker(marker) {
    marker.setMap(null)
    marker = null
}

export default {
    createMarker,
    setCentre,
    fitMarkers,
    clearMarkers,
    clearMarker,
    initMap,
    initMaps,
}