import { fetchAPI } from "../utils/utils"
import { getCaseId } from "../cases/case"

const DELETED_MESSAGE_CONTENT = '❌ Message Deleted'

export function initialiseMessages(caseItem) {
    const messageContainer = document.querySelector('.messages')
    const sendMessageContainer = document.querySelector('.send-message')
    setupSendReceive(sendMessageContainer, caseItem.id)

    getMessages(caseItem.id)
        .then(({ messages }) => {
            makeMessages(messages, caseItem.user_id, messageContainer)
        })
        .then(() => {
            scrollBottom(messageContainer)
            messageContainer.addEventListener('scroll', scrollHandler)
        })
}

function getMessages(caseId, args) {
    const { created_at } = args || {}
    const url = new URL(`${API_URL}/cases/${caseId}/messages`)
    created_at && url.searchParams.append('olderThan', created_at)

    return fetchAPI(url)
        .then(({ data }) => data)
}

function makeMessages(messages, requesterId, container) {
    const authUser = sos.ls.get('authUser')
    let currentUserId
    const messageElements = messages.reverse().map((message) => {
        const showSender = (message.user_id !== currentUserId && authUser.id !== message.user_id)
        const messageItem = makeMessage(message, {
            showSender,
            requesterId,
        })
        currentUserId = message.user_id
        return messageItem
    })
    messageElements.reverse().forEach((messageItem) => {
        container.prepend(messageItem)
    })
}

function makeMessage(message, { showSender = true, requesterId, sending }) {
    const { id, content, created_at, user_id, user, deleted } = message
    const template = getMessageTemplate()
    const authUser = sos.ls.get('authUser')
    const isAuthUser = user_id === authUser.id

    const clone = template.content.cloneNode(true)
    const timeElement = clone.querySelector('time')
    const messageElement = clone.querySelector('.message')

    clone.querySelector('.text').innerText = deleted ? DELETED_MESSAGE_CONTENT : content
    clone.querySelector('.sender').innerText = (user !== null && showSender) ? user.full_name : ''
    timeElement.innerText = moment(created_at).fromNow()
    timeElement.setAttribute('datetime', created_at)
    const senders = {
        [authUser.id]: 'self',
        [requesterId]: 'user',
    }
    messageElement.dataset.sender = senders[user_id] || 'responder'
    messageElement.dataset.senderId = user_id
    messageElement.dataset.id = id
    if (sending === true) messageElement.classList.add('sending')
    if (!sending && isAuthUser) messageElement.classList.add('sent')
    if (isAuthUser) {
        if (!deleted) {
            messageElement.classList.add('popup')
            messageElement.addEventListener('click', handleMessageOptions)
            messageElement.querySelector('.delete-button').addEventListener('click', handleDeleteMessage)
            messageElement.querySelector('.delete-button').dataset.messageId = id
        } else {
            messageElement.style.cursor = 'initial'
        }
    }
    return clone
}

function handleMessageOptions(e) {
    const targetElement = e.target

    let isMessageElement = (element) => element.classList.contains('message')

    let currentElement = targetElement
    while (!isMessageElement(currentElement)) {
        currentElement = currentElement.parentElement
    }

    let messageElement = currentElement
    closeOtherPopups(messageElement)
    messageElement.querySelector('.popuptext').classList.toggle('show')
}

function closeOtherPopups(messageElement) {
    const messagesElement = document.querySelector('.messages')
    const popups = messagesElement.querySelectorAll('.popuptext')
    const elementToLeaveAlone = messageElement.querySelector('.popuptext')
    popups.forEach((popup) => {
        if (popup === elementToLeaveAlone) return
        popup.classList.remove('show')
    })
}

function handleDeleteMessage(e) {
    const caseId = getCaseId()
    const messageId = e.target.dataset.messageId
    const url = `${API_URL}/cases/${caseId}/messages/${messageId}`
    fetchAPI(url, {
        method: 'DELETE'
    })
        .then(({ data }) => {
            markMessageAsDeleted(data)
        })
        .catch((error) => {
            console.error(error);
        })
}

function loadMoreMessages(caseId, element) {
    element.dataset.loading = true
    const lastMessage = element.firstElementChild
    const lastMessageId = lastMessage.dataset.id
    if (lastMessageId === '1') return

    const requesterId = document.querySelector('.initiator').dataset.id
    const lastMessageDate = lastMessage.querySelector('time').getAttribute('datetime')

    getMessages(caseId, { created_at: lastMessageDate })
        .then(({ moreMessages }) => {
            const lastScrollHeight = element.scrollHeight
            const lastScrollTop = element.scrollTop
            makeMessages(moreMessages, requesterId, element)
            maintainScroll(element, lastScrollHeight, lastScrollTop)
        })
        .catch((error) => { console.error(error); })
        .finally(() => {
            element.dataset.loading = ''
        })
}

export function addNewMessage(message) {

    const authUser = sos.ls.get('authUser')
    if (message.user_id === authUser.id) {
        console.log('marking admin message as sent', message.id);
        const messageElement = document.querySelector(`.message[data-id="${message.id}"]`)
        console.log(messageElement);
        messageElement.classList.remove('sending')
        messageElement.classList.add('sent')
        // style ::after to contain a tick or timer depending on class
        return
    }
    const messagesContainer = document.querySelector('.messages')
    const messageTemplate = getMessageTemplate()
    const requesterId = document.querySelector('.initiator').dataset.id
    const lastMessage = messagesContainer.lastElementChild
    const lastSenderId = lastMessage ? lastMessage.dataset.senderId : null
    const messageElement = makeMessage(message, {
        template: messageTemplate,
        showSender: !lastSenderId || lastSenderId === message.user_id,
        requesterId,
    })
    messagesContainer.appendChild(messageElement)
    scrollBottom(messagesContainer)
}

export function markMessageAsDeleted(message) {
    const messagesContainer = document.querySelector('.messages')
    const messageElement = messagesContainer.querySelector(`.message[data-id="${message.id}"]`)
    messageElement ? messageElement.querySelector('.text').innerText = DELETED_MESSAGE_CONTENT : null
    messageElement.removeEventListener('click', handleMessageOptions)
    messageElement.style.cursor = 'initial'
}

function getMessageTemplate() {
    return document.getElementById('message-template')
}

function setupSendReceive(container, caseId) {
    const textElement = container.querySelector('.text')
    const sendElement = container.querySelector('.send-button')

    sendElement.addEventListener('click', handleSendMessage(textElement, caseId))
}

function handleSendMessage(textElement, caseId) {
    const messagesContainer = document.querySelector('.messages')
    return function (e) {
        const messageText = textElement.value
        if (messageText === '') return

        // send message via API
        const url = `${API_URL}/cases/${caseId}/messages`
        fetchAPI(url, {
            method: 'POST',
            body: {
                content: messageText,
            }
        })
            .then(({ data }) => {
                const message = makeMessage(data, {
                    showSender: false,
                    sending: true,
                })
                messagesContainer.appendChild(message)
                scrollBottom(messagesContainer)
                textElement.value = ""
            })
    }
}

function scrollHandler(e) {
    const element = e.target
    const id = element.dataset.id
    const loading = element.dataset.loading
    const { scrollTop } = element
    if (scrollTop < 50 && !loading) {
        loadMoreMessages(id, element)
    }
}
function scrollBottom(element) {
    element.scrollTop = element.scrollHeight - element.clientHeight
}
function maintainScroll(element, lastScrollHeight, lastScrollTop) {
    element.scrollTop = element.scrollHeight - lastScrollHeight + lastScrollTop
}