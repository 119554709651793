const SOS_PREFIX = 'SOS_'

export function get(key) {
    const item = localStorage.getItem(addPrefix(key))
    try {
        return JSON.parse(item)
    } catch (error) {
        return item
    }
}

export function set(key, val) {
    localStorage.setItem(addPrefix(key), JSON.stringify(val))
}

export function clear(key, val) {
    localStorage.removeItem(addPrefix(key), val)
}

export function cacheResult(data, key) {
    set(key, data)
    return data
}

function addPrefix(key) {
    return SOS_PREFIX + key
}

export function clearStorage() {
    localStorage.clear()
}

export default {
    get,
    set,
    clear,
    cacheResult,
    clearStorage,
}